@tailwind components;
@tailwind utilities;

.navbar-toggler {
    border: none;
}

.btn, .btn:hover {
    border-color: var(--brand-color);
}
.btn.btn-fill {
    background-color: var(--brand-color);
}

.btn-custom {
    color: #fff;
}
.btn-estimate-round:hover {
    color: #000;
}